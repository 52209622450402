import api from './request';

// 没有返回系统时，跳转到权限中心
// 2.1.1 获取登陆用户拥有的系统
export const getUserSystems = (data: ApiSys.SystemInfoParams) =>
  api.postV2<ApiSys.SystemInfo>({
    url: 'retail/access/control/getUserSystems',
    data,
    params: {
      apiTenant: 6
    }
  });

// 2.1.2 获取用户拥有的项目(修改)
export const getUserProjects = (data: any) =>
  api.postV2({
    url: 'retail/access/control/getUserProjects',
    data,
    params: {
      apiTenant: 6
    }
  });
